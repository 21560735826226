<template>
  <v-row>
    <v-col
      cols="4"
      v-for="item in menuItems"
      :key="item.icon"
      class="text-center"
    >
      <v-btn icon @click="() => onClick(item)">
        <v-icon x-large>{{ item.icon }}</v-icon>
      </v-btn>
      <p
        class="body-2 grey--text mt-2 cursor-pointer"
        @click="() => onClick(item)"
      >
        {{ item.text }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import client from "@/utils/ApiClient";
import { mapGetters } from "vuex";

export default {
  name: "GridMenu",
  props: {
    value: Boolean,
  },
  computed: {
    ...mapGetters(["groupsSet", "accountSet", "menuItems"]),
  },
  methods: {
    onClick(item) {
      if (item.path) {
        this.$router.push(item.path);
      } else if (item.action) {
        this.$store.dispatch(item.action);
      }
    },
  },
};
</script>
