import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const initialState = () => ({
  refreshInterval: 20,
});

export default {
  state: initialState(),
  getters: {
    menuItems: (state, getters, rootState) => [
      {
        icon: "mdi-format-list-checkbox",
        text: "Önskningar",
        path: `/accounts/${rootState.accounts.primaryAccount}`,
      },
      { icon: "mdi-checkbox-marked", text: "Bokningar", path: "/bookings" },
      {
        icon: "mdi-account-multiple",
        text: "Vänner",
        path: `/friends`,
      },
      {
        icon: "mdi-account-group",
        text: "Grupper",
        path: `/groups`,
      },
      {
        icon: "mdi-account-supervisor",
        text: "Hanterade konton",
        path: "/managedaccounts",
      },
      { icon: "mdi-logout", text: "Logga ut", action: "logout" },
    ],
  },
  mutations: {
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  actions: {},
};
