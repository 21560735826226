<template>
  <div>
    <v-slide-y-reverse-transition group>
      <v-snackbar
        v-for="(notification, index) in notifications"
        :style="{ bottom: bottomMargin(index) + 'px' }"
        :color="notification.color"
        :key="index"
        v-model="notification.open"
        :timeout="6000"
      >
        {{ notification.message }}
        <template v-slot:action="{}">
          <v-btn icon color="white" @click="notification.open = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-slide-y-reverse-transition>
  </div>
</template>

<script>
export default {
  name: "NotifyHub",
  data: () => ({
    notifications: [],
    spacing: 8,
  }),
  created() {
    this.$bus.$on("notify", (notification) => {
      this.newNotification(notification);
    });
  },
  methods: {
    newNotification(notification) {
      const message =
        typeof notification === "string" ? notification : notification.message;
      this.notifications.push({
        open: true,
        color: notification.color || "default",
        message,
      });
    },

    bottomMargin(index) {
      const order = this.notifications.filter(
        (n, i) => i < index && n.open === true
      ).length;
      return this.spacing + order * (52 + this.spacing);
    },
  },
};
</script>
