import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { Plugins } from "@capacitor/core";
const { App } = Plugins;

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/addfriendaccount",
    name: "AddFriendAccount",
    component: () =>
      import(
        /* webpackChunkName: "addfriendaccount" */ "@/views/AddFriendAccount.vue"
      ),
  },
  {
    path: "/accounts/:id",
    name: "Account",
    component: () =>
      import(/* webpackChunkName: "account" */ "@/views/Account.vue"),
  },
  {
    path: "/accounts/:id/share",
    name: "AccountShare",
    component: () =>
      import(/* webpackChunkName: "accountshare" */ "@/views/AccountShare.vue"),
  },
  {
    path: "/accounts/:id/settings",
    name: "AccountSettings",
    component: () =>
      import(
        /* webpackChunkName: "accountsettings" */ "@/views/AccountSettings.vue"
      ),
  },
  {
    path: "/a/:token",
    name: "PublicAccount",
    component: () =>
      import(
        /* webpackChunkName: "publicaccount" */ "@/views/PublicAccount.vue"
      ),
  },
  {
    path: "/managedaccounts",
    name: "ManagedAccounts",
    component: () =>
      import(
        /* webpackChunkName: "managedaccounts" */ "@/views/ManagedAccounts.vue"
      ),
  },
  {
    path: "/createmanagedaccount",
    name: "CreateManagedAccount",
    component: () =>
      import(
        /* webpackChunkName: "createmanagedaccount" */ "@/views/CreateManagedAccount.vue"
      ),
  },
  {
    path: "/bookings",
    name: "Bookings",
    component: () =>
      import(/* webpackChunkName: "bookings" */ "@/views/Bookings.vue"),
  },
  {
    path: "/friends",
    name: "Friends",
    component: () =>
      import(/* webpackChunkName: "friends" */ "@/views/Friends.vue"),
  },
  {
    path: "/groups",
    name: "Groups",
    component: () =>
      import(/* webpackChunkName: "groups" */ "@/views/Groups.vue"),
  },
  {
    path: "/groups/:id",
    name: "Group",
    component: () =>
      import(/* webpackChunkName: "group" */ "@/views/Group.vue"),
  },
  {
    path: "/groups/:id/share",
    name: "GroupShare",
    component: () =>
      import(/* webpackChunkName: "groupshare" */ "@/views/GroupShare.vue"),
  },
  {
    path: "/groups/:id/settings",
    name: "GroupSettings",
    component: () =>
      import(
        /* webpackChunkName: "groupsettings" */ "@/views/GroupSettings.vue"
      ),
  },
  {
    path: "/g/:token",
    name: "PublicGroup",
    component: () =>
      import(/* webpackChunkName: "publicgroup" */ "@/views/PublicGroup.vue"),
  },
  {
    path: "/newwish",
    name: "NewWish",
    component: () =>
      import(/* webpackChunkName: "newwish" */ "@/views/NewWish.vue"),
  },
  {
    path: "/newgroup",
    name: "NewGroup",
    component: () =>
      import(/* webpackChunkName: "newgroup" */ "@/views/NewGroup.vue"),
  },
  {
    path: "/resetpassword/:email/:redirect",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "resetpassword" */ "@/views/ResetPassword.vue"
      ),
  },
  {
    path: "/wishes/:id",
    name: "Wish",
    component: () =>
      import(/* webpackChunkName: "wishes" */ "@/views/Wish.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Deep linking for mobile apps
// App.addListener('appUrlOpen', function (data) {
//   const slug = data.url.split('onskeappen.se').pop();

//   // We only push to the route if there is a slug present
//   if (slug) {
//     router.push({
//       path: slug,
//     });
//   }
// });

export default router;
