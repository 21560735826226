<template>
  <v-card>
    <v-form ref="form" lazy-validation v-model="validLoginForm">
      <v-card-text>
        <Info v-if="potentiallyNewUser" id="loginOrCreateAccount"
          >För att kunna se önskelistan behöver du logga in eller
          <a @click.prevent="$emit('signup')">skapa ett konto</a>.</Info
        >
        <div class="d-flex flex-column">
          <div>
            <v-text-field
              id="email-input"
              label="Epost"
              type="email"
              prepend-icon="mdi-email"
              required
              autocomplete="email"
              v-model="emailValue"
              :rules="emailRules"
            />
          </div>
          <div>
            <v-text-field
              id="password-input"
              label="Lösenord"
              required
              type="password"
              prepend-icon="mdi-key"
              autocomplete="current-password"
              v-model="password"
              :rules="passwordRules"
            />
          </div>
          <div class="text-center">
            <a
              href="#"
              id="reset-password-link"
              @click.prevent="$emit('resetPassword')"
              >Jag har glömt mitt lösenord</a
            >
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="signup-button"
          color="primary"
          text
          @click.prevent="$emit('signup')"
          >Skapa nytt konto</v-btn
        >
        <v-btn
          id="login-button"
          type="submit"
          color="primary"
          @click.prevent="login()"
          :loading="isLoggingIn"
          :disabled="isLoggingIn || !validLoginForm"
          >Logga in</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import client from "@/utils/ApiClient";
import Info from "./Info";

export default {
  name: "LoginForm",
  components: {
    Info,
  },
  props: {
    email: String,
  },
  data: function () {
    return {
      isLoggingIn: false,
      password: null,
      validLoginForm: false,
      emailRules: [
        (v) => !!v || "Vänligen fyll i epost",
        (v) => /.+@.+\..+/.test(v) || "Vänligen fyll i en giltig epost",
      ],
      passwordRules: [
        (v) => !this.isLoggingIn || !!v || "Vänligen fyll i lösenord", // Lösenord är bara obligatoriskt vid inloggning, inte vid lösenordsåterställning
      ],
    };
  },
  computed: {
    emailValue: {
      get: function () {
        return this.email;
      },
      set: function (value) {
        this.$emit("update:email", value);
      },
    },
    potentiallyNewUser() {
      return this.$route.name === "PublicAccount";
    },
  },
  methods: {
    async login(provider) {
      this.isLoggingIn = true;
      if (!this.$refs.form.validate() || !this.validLoginForm) {
        this.isLoggingIn = false;
        return;
      }
      const credentials = provider || {
        email: this.email,
        password: this.password,
      };
      this.$store
        .dispatch("login", credentials)
        .then((res) => {
          this.isLoggingIn = false;
          this.$emit("close");
        })
        .catch((res) => {
          this.$notify({
            message: "Inloggningen misslyckades, vänligen försök igen",
            color: "error",
          });
          this.isLoggingIn = false;
        });
    },
  },
};
</script>
