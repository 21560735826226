<template>
  <v-card>
    <v-form ref="form" lazy-validation v-model="validResetPasswordForm">
      <v-card-text>
        <p class="text-body-1">
          Återställ ditt lösenord genom att fylla i din epost och klicka på
          "Återställ". Du får då ett mail med ytterligare instruktioner.
        </p>
        <div class="d-flex flex-column">
          <div>
            <v-text-field id="email-input" label="Epost" type="email" prepend-icon="mdi-email" required
              v-model="emailValue" :rules="emailRules" />
          </div>
        </div>
        <v-alert v-if="hasSubmitted" type="info">
          <p v-if="failed">Misslyckades att skicka återställningsmail.</p>
          <p v-else>Följ instruktionerna i återställningsmailet som kommer snart.</p>

          <p>
            Om du aldrig får något mail med återställningslänk kan du höra
            av dig till <a href="mailto:support@onskeappen.se">support@onskeappen.se</a>
          </p>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="login-button" color="primary" text @click.prevent="$emit('login')">Avbryt</v-btn>
        <v-btn id="reset-button" type="submit" color="primary" @click.prevent="resetPassword()" :loading="isResetting"
          :disabled="isResetting || !validResetPasswordForm">Återställ</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import client from "@/utils/ApiClient";

export default {
  name: "ResetPasswordForm",
  props: {
    email: String,
  },
  data: function () {
    return {
      isResetting: false,
      validResetPasswordForm: false,
      failed: false,
      hasSubmitted: false,
      emailRules: [
        (v) => !!v || "Vänligen fyll i epost",
        (v) => /.+@.+\..+/.test(v) || "Vänligen fyll i en giltig epost",
      ],
    };
  },
  computed: {
    emailValue: {
      get: function () {
        return this.email;
      },
      set: function (value) {
        this.$emit("update:email", value);
      },
    },
  },
  methods: {
    async resetPassword() {
      if (!this.$refs.form.validate() || !this.validResetPasswordForm) {
        return;
      }
      this.isResetting = true;
      this.$store
        .dispatch("resetPassword", this.email)
        .then(() => {
          this.failed = false;
        })
        .catch(() => {
          this.failed = true;
        })
        .finally(() => {
          this.isResetting = false;
          this.hasSubmitted = true;
        });
    },
  },
};
</script>
