<template>
  <v-fade-transition>
    <div
      class="
        overlay
        primary
        d-flex
        flex-column
        justify-space-around
        align-center
      "
    >
      <div class="content">
        <div class="d-flex flex-row justify-center align-center mb-4">
          <img
            :src="require('../assets/enbart-logga-inverted.svg')"
            height="20"
          />
          <h1 class="ml-2">ÖNSKEAPPEN</h1>
        </div>
        <slot>
          <div v-if="autoLoggingIn" class="d-flex flex-column align-center">
            <v-progress-linear indeterminate color="white" size="200" />
          </div>
          <SignupForm
            v-else-if="visibleForm === 'signup'"
            @login="visibleForm = 'login'"
            :email.sync="email"
          />
          <ResetPasswordForm
            v-else-if="visibleForm === 'resetPassword'"
            @login="visibleForm = 'login'"
            :email.sync="email"
          />
          <LoginForm
            v-else
            @signup="visibleForm = 'signup'"
            @resetPassword="visibleForm = 'resetPassword'"
            :email.sync="email"
          />
        </slot>
      </div>
      <PWAPrompt
        copyTitle="Installera Önskeappen"
        copyBody="Önskeappen finns inte i App store än, men du kan enkelt installera den genom att:"
        copyShareButtonLabel="1. Klicka på dela-knappen i menyn nedanför"
        copyAddHomeButtonLabel='2. Klicka på "Lägg till på hemskärmen"'
        copyClosePrompt="Avbryt"
      />
    </div>
  </v-fade-transition>
</template>

<script>
import PWAPrompt from "vue2-ios-pwa-prompt";
import LoginForm from "../components/LoginForm.vue";
import SignupForm from "../components/SignupForm.vue";
import ResetPasswordForm from "../components/ResetPasswordForm.vue";
import client from "@/utils/ApiClient";

export default {
  name: "Splash",
  data: () => ({
    visibleForm: "login",
    autoLoggingIn: true,
    email: "",
  }),
  components: {
    PWAPrompt,
    LoginForm,
    SignupForm,
    ResetPasswordForm,
  },
  async created() {
    if (this.$route.query.signup !== undefined) {
      this.visibleForm = "signup";
    }
    if (client.config.token) {
      await this.$store
        .dispatch("setUserData")
        .catch((err) => (this.autoLoggingIn = false));
    }
    this.autoLoggingIn = false;
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  z-index: 200; /* vuetify-confirm overlay has 202 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow-y: auto;
}

.content {
  width: 100%;
  max-width: 350px;
  height: 100%;
}

h1 {
  color: white;
  font-weight: 200;
  font-family: "Caveat", cursive;
}
</style>
