<template>
  <div id="boundary">
    <v-app id="keep">
      <v-app-bar app color="primary" clipped-right dark id="navbar">
        <div
          class="d-flex flex-row justify-center align-center cursor-pointer"
          id="logo"
          @click="$router.push({ name: 'Home' })"
        >
          <img
            :src="require('./assets/enbart-logga-inverted.svg')"
            height="20"
          />
          <h1 class="ml-2">ÖNSKEAPPEN</h1>
        </div>

        <v-spacer></v-spacer>
        <v-btn icon @click="onClickRefresh" :loading="isRefreshing">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-app-bar-nav-icon
          @click="$store.commit('toggleDrawer')"
        ></v-app-bar-nav-icon>
      </v-app-bar>

      <NavigationDrawer />
      <v-main>
        <Splash v-if="showSplash" />
        <router-view v-else />
        <NotifyHub />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import client from "@/utils/ApiClient";
import { mapState, mapGetters } from "vuex";
import NotifyHub from "@/components/NotifyHub";
import NavigationDrawer from "@/components/NavigationDrawer";
import Splash from "@/views/Splash";

export default {
  name: "App",

  components: {
    NotifyHub,
    NavigationDrawer,
    Splash,
  },

  data: () => ({
    isRefreshing: false,
    refreshTimer: null,
  }),
  computed: {
    ...mapState({
      refreshInterval: (state) => state.ui.refreshInterval,
    }),
    ...mapGetters({
      primaryAccount: "getPrimaryAccount",
      isLoggedIn: "isLoggedIn",
    }),
    showSplash() {
      return (
        !this.isLoggedIn && this.$route.path.indexOf("resetpassword") === -1
      );
    },
  },
  methods: {
    async refresh() {
      if (!client.config.token) {
        return;
      }
      this.isRefreshing = true;
      try {
        await this.$store.dispatch("setUserData");
      } catch (err) {
        console.log({ err });
        if (/Unauthorized request/.test(err.message)) {
          this.$notify.error("Du har blivit utloggad, logga in igen");
          await this.$store.dispatch("logout");
        }
      }
      this.isRefreshing = false;
    },
    async restartRefreshTimer() {
      clearInterval(this.refreshTimer);
      this.refreshTimer = setInterval(
        this.refresh,
        this.refreshInterval * 1000
      );
    },
    onClickRefresh() {
      this.restartRefreshTimer();
      this.refresh();
    },
  },
  mounted() {
    this.restartRefreshTimer();
  },
};
</script>

<style>
#boundary {
  max-width: 960px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 50px #ddd;
  margin: 0 auto;
}

header.v-app-bar,
nav.v-navigation-drawer {
  position: absolute !important;
}

#logo h1 {
  color: white;
  font-weight: 200;
  font-family: "Caveat", cursive;
}

/******* Helper classes (should end up in separate file) */
.cursor-pointer {
  cursor: pointer;
}
</style>
