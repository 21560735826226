import Vuetify from "vuetify/lib/framework";

export const useVuetify = (Vue) => {
  Vue.use(Vuetify);
  return new Vuetify({
    theme: {
      themes: {
        light: {
          primary: "#4d9232",
          info: "#bb5505",
        },
      },
      options: {
        customProperties: true,
      },
    },
  });
};
