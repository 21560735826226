import { times, random } from "lodash";
import linkifyStr from "linkify-string";

export function generateToken(length) {
  // Generates a random string
  length = length ?? 8;
  return times(length, () => random(35).toString(36)).join("");
}

export function extractLinks(text) {
  const links = new Set();
  linkifyStr(text, {
    format: (value, type) => {
      if (type === "url") {
        links.add(value);
      }
      return value;
    },
  });
  return [...links];
}

export function linkifyString(text) {
  return linkifyStr(text, {
    target: "_blank",
    format: (value, type) => {
      if (type === "url" && value.length > 50) {
        return `${value.substr(0, 35)}[...]${value.substr(-10)}`;
      }
      return value;
    },
  });
}
