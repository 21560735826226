<template>
  <v-card>
    <v-form lazy-validation v-model="validForm" ref="form">
      <v-card-text>
        <div class="d-flex flex-column">
          <div>
            <v-text-field
              id="email-input"
              label="Epost"
              type="email"
              prepend-icon="mdi-email"
              required
              autocomplete="email"
              v-model="emailValue"
              :rules="emailRules"
            />
          </div>
          <div class="d-flex flex-row">
            <v-text-field
              id="firstname-input"
              label="Förnamn"
              type="text"
              prepend-icon="mdi-account"
              required
              autocomplete="given-name"
              v-model="firstname"
              :rules="firstnameRules"
            />
            <v-text-field
              id="lastname-input"
              label="Efternamn"
              type="text"
              required
              autocomplete="family-name"
              v-model="lastname"
              :rules="lastnameRules"
              class="ml-2"
            />
          </div>
          <div>
            <v-text-field
              id="birthdate-input"
              label="Födelsedatum"
              prepend-icon="mdi-calendar"
              type="date"
              autocomplete="bday"
              placeholder="åååå-mm-dd"
              v-model="birthdate"
            />
          </div>
          <div class="d-flex flex-row">
            <v-text-field
              id="password-input"
              label="Lösenord"
              type="password"
              prepend-icon="mdi-key"
              required
              autocomplete="new-password"
              v-model="password"
              :rules="passwordRules"
            />
            <v-text-field
              id="password-confirm-input"
              label="Bekräfta lösenord"
              type="password"
              required
              autocomplete="new-password"
              v-model="passwordConfirm"
              :rules="passwordConfirmRules"
              class="ml-2"
            />
          </div>
          <div>
            <v-checkbox v-model="acceptAgreement" required>
              <template slot="label">
                Jag godkänner&nbsp;
                <a
                  href="https://onskeappen.se/sekretesspolicy.html"
                  target="_blank"
                  class="text-body-1"
                  @click.stop
                >
                  användarvillkoren
                </a>
              </template>
            </v-checkbox>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" text @click="closeModal()">Avbryt</v-btn> -->
        <v-btn
          color="primary"
          text
          @click.prevent="$emit('login')"
          id="login-button"
          >Logga in istället</v-btn
        >
        <v-btn
          type="submit"
          color="primary"
          @click.prevent="signup()"
          :loading="isSigningUp"
          :disabled="isSigningUp || !validForm"
          id="signup-button"
          >Skapa konto</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import client from "@/utils/ApiClient";
import { pick } from "lodash";

export default {
  name: "SignupForm",
  components: {},
  props: {
    email: String,
  },
  data: function () {
    return {
      isSigningUp: false,
      firstname: null,
      lastname: null,
      birthdate: null,
      password: null,
      passwordConfirm: null,
      validForm: true,
      acceptAgreement: false,
      emailRules: [
        (v) => !!v || "Vänligen fyll i epost",
        (v) => /.+@.+\..+/.test(v) || "Vänligen fyll i en giltig epost",
      ],
      firstnameRules: [(v) => !!v || "Vänligen fyll i förnamn"],
      lastnameRules: [(v) => !!v || "Vänligen fyll i efternamn"],
      passwordRules: [
        (v) => v?.length > 7 || "Lösenordet behöver vara minst 8 tecken",
      ],
      passwordConfirmRules: [
        (v) => !!v || "Vänligen bekräfta lösenord",
        (v) => v === this.password || "Lösenorden stämmer inte överens",
      ],
    };
  },
  computed: {
    emailValue: {
      get: function () {
        return this.email;
      },
      set: function (value) {
        this.$emit("update:email", value);
      },
    },
  },
  methods: {
    closeModal() {
      this.isSigningUp = false;
      this.emailValue = null;
      this.firstname = null;
      this.lastname = null;
      this.birthdate = null;
      this.password = null;
      this.passwordConfirm = null;
      this.$emit("close");
    },
    async signup(provider) {
      if ((await this.$refs.form.validate()) === false) {
        return;
      }
      if (
        !this.birthdate &&
        (await this.$confirm(
          "Om du inte anger födelsedatum kommer vissa funktioner att fungera sämre. Fortsätta ändå?"
        )) === false
      ) {
        return;
      }
      this.isSigningUp = true;
      const userInformation = pick(this, [
        "firstname",
        "lastname",
        "birthdate",
        "password",
        "email",
      ]);
      this.$store
        .dispatch("signup", userInformation)
        .then((res) => {
          this.isSigningUp = false;
          this.closeModal();
          this.$emit("complete");
        })
        .catch((res) => {
          console.log("message: " + res.message);
          if (/Duplicate key/.test(res.message)) {
            this.$notify({
              message: "Eposten används redan av en användare",
              color: "error",
            });
          } else {
            this.$notify({
              message: "Registreringen misslyckades, vänligen försök igen",
              color: "error",
            });
          }

          this.isSigningUp = false;
        });
    },
  },
};
</script>
