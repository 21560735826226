<template>
  <v-btn v-if="supported && !subscribed" @click="subscribe"
    >Aktivera notifieringar</v-btn
  >
</template>

<script>
const VAPID_PUBLIC_KEY =
  "BO0Ug7q1trbO_CZwoQLSFMg_h6G78M4S0Vk8Kx8fUInLzeDU1YvmLbNIUz2PLQNUTskIotwUZIOswfNpI6WS9zs";
export default {
  name: "WebPush",
  props: {},
  data: () => ({
    subscribed: false,
    supported: false,
  }),
  computed: {},
  methods: {
    async subscribe() {
      if (Notification?.permission === "denied") {
        return;
      }
      const result = await Notification?.requestPermission();
      if (result === "granted") {
        this.subscribed = true;
        const registration = await navigator.serviceWorker.getRegistration();
        const subscribed = await registration.pushManager.getSubscription();
        if (subscribed) {
          console.info("User is already subscribed.");
          return;
        }
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: VAPID_PUBLIC_KEY,
        });
        console.log({ registration, subscription: subscription.toJSON() });
        // TODO: send to backend
      }
    },
  },
  mounted() {
    if (!!window && "Notification" in window) {
      this.supported = true;
    }
    if (Notification?.permission === "granted") {
      this.subscribed = true;
    }
  },
  watch: {},
};
</script>
