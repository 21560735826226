import Vue from "vue";
import Vuex from "vuex";
import client from "@/utils/ApiClient";
import { normalize, schema } from "normalizr";
import { merge, union } from "lodash";
import stateMerge from "vue-object-merge";
import { generateJunctionTableUpdate } from "../../utils/ApiUtils";

const accountsSchema = new schema.Entity("accounts");
const groupMembersSchema = new schema.Object({ account: accountsSchema });
const accountsListSchema = new schema.Array(groupMembersSchema);

const groupsSchema = new schema.Entity("groups", {
  members: accountsListSchema,
});
const groupsListSchema = new schema.Array(groupsSchema);

Vue.use(Vuex);
const initialState = () => ({
  groups: {},
  groupIds: [],
});

export default {
  state: initialState(),

  getters: {
    groupsSet: (state) => state.groupIds.map((id) => state.groups[id]),
    isAccountInGroups: (state, getters) => (accountId) =>
      getters.groupsSet.some((g) =>
        g.members.some((m) => m.account === accountId)
      ),
  },

  mutations: {
    setGroups(state, payload) {
      stateMerge(state.groups, payload.groups ?? []);
      state.groupIds = union(state.groupIds, payload.groupIds);
      state.groups = { ...state.groups }; // Lite tråkigt sätt att trigga reaktiviteten
    },

    deleteGroup(state, id) {
      state.groups[id] = undefined;
      state.groupIds = state.groupIds.filter((g) => g !== id);
    },

    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  actions: {
    async getGroupsByAccounts(context, accounts) {
      return await context.dispatch("getGroupsByFilter", {
        "filter[members.account][in]": accounts.join(","),
      });
    },

    async getGroupsByIds(context, ids) {
      return await context.dispatch("getGroupsByFilter", {
        "filter[id][in]": ids.join(","),
      });
    },

    async getGroupsByFilter(context, filter) {
      const res = await client.getItems("groups", {
        ...filter,
        fields: "*,members.*,members.account.*",
      });
      const normalizedGroups = normalize(res.data, groupsListSchema);
      context.commit("setGroups", {
        groups: normalizedGroups.entities.groups,
        groupIds: normalizedGroups.result,
      });
      const normalizedAccounts = normalize(
        normalizedGroups.entities.accounts ?? [],
        [accountsSchema]
      );
      if (normalizedAccounts.result.length > 0) {
        await context.dispatch("getAccountsByIds", normalizedAccounts.result);
      }
    },

    async fetchGroupInformation(context, id) {
      return client
        .getItem("groups", id, { fields: "*,members.*,members.account.*" })
        .then((res) => res.data);
    },

    async createGroup(context, { group, members, admins }) {
      const groupMembers = admins.map((a) => ({
        account: { id: a },
        is_admin: true,
      }));
      groupMembers.push(
        ...members
          .filter((m) => !admins.includes(m))
          .map((m) => ({ account: { id: m }, is_admin: false }))
      );
      const res = await client.createItem("groups", {
        ...group,
        members: groupMembers,
      });
      const newGroup = {
        ...res.data,
        members: groupMembers.map((m) => ({
          account: m.account.id,
          is_admin: m.is_admin,
        })),
      };
      newGroup.owner = { id: newGroup.owner };
      context.commit("setGroups", {
        groups: { [newGroup.id]: newGroup },
        groupIds: [newGroup.id],
      });
      return res.data;
    },

    async updateGroup(context, { group, members, admins }) {
      const groupMembers = admins.map((a) => ({
        account: a,
        is_admin: true,
      }));
      groupMembers.push(
        ...members
          .filter((m) => !admins.includes(m))
          .map((m) => ({ account: m, is_admin: false }))
      );
      const originalGroup = context.state.groups[group.id];
      const updateMembers = generateJunctionTableUpdate(
        originalGroup.members,
        groupMembers,
        "account"
      );
      const res = await client.updateItem("groups", group.id, {
        ...group,
        members: updateMembers,
      });
      await context.dispatch("getGroupsByIds", [group.id]);
      return res.data;
    },

    async deleteGroup(context, id) {
      return client
        .updateItem("groups", id, { status: "deleted" })
        .then((res) => context.commit("deleteGroup", id));
    },

    async joinGroup(context, { groupId, accountIds }) {
      for (const accountId of accountIds) {
        await client.createItem("group_members", {
          account: { id: accountId },
          group: { id: groupId },
          is_admin: false,
        });
      }
      await context.dispatch("getGroupsByAccounts", accountIds);
    },
  },
};
