import Vue from "vue";
import Vuex from "vuex";
import accounts from "./modules/accounts.store";
import friendships from "./modules/friendships.store";
import groups from "./modules/groups.store";
import ui from "./modules/ui.store";
import wishes from "./modules/wishes.store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    me: {},
    drawer: undefined,
  },
  mutations: {
    updateMe(state, payload) {
      state.me = payload;
    },
    toggleDrawer(state, show) {
      state.drawer = show ?? !state.drawer;
    },
  },
  actions: {
    loadInitState(context) {
      return;
      // client
      //   .getMe()
      //   .then((response) => {
      //     context.commit("updateMe", {
      //       isLoggedIn: true,
      //       firstname: response.data.first_name,
      //       lastname: response.data.last_name,
      //       email: response.data.email,
      //       id: response.data.id,
      //     });
      //   })
      //   .catch((res) => {
      //     console.log(res);
      //   });
    },
  },
  modules: {
    accounts,
    friendships,
    groups,
    ui,
    wishes,
  },
});
