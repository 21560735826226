import DirectusSDK from "@directus/sdk-js";
import axios from "axios";

class ExtendedDirectusSDK extends DirectusSDK {
  appUrl;
  constructor(options) {
    super(options);
    this.appUrl = options.appUrl;
  }

  saveItem(collection, body) {
    const { id, modified_by, modified_on, created_on, owner, ...newBody } =
      body;
    return id
      ? this.updateItem(collection, id, newBody)
      : this.createItem(collection, newBody);
  }

  facebookLogin() {
    return axios
      .get(
        this.config.url +
          this.config.project +
          "/auth/sso/facebook?mode=cookie&redirect_url=" +
          encodeURI(window.location)
      )
      .then((response) => {
        const facebookUrl = response.data.data.authorization_url;
        axios.get(facebookUrl).then(console.log);
      })
      .error((res) => console.log(res));
  }

  getOgData(url) {
    return axios
      .post(this.config.url + "/og.php", {
        url,
      })
      .then((res) => res.data);
  }
}

const client = new ExtendedDirectusSDK({
  url: "https://api.onskeappen.se/",
  appUrl: "https://app.onskeappen.se",
  project: "onskeappen",
  storage: window.localStorage,
  persist: true,
});

export default client;
