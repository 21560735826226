import Vue from "vue";
import Vuex from "vuex";
import client from "@/utils/ApiClient";
import { normalize, schema } from "normalizr";
import { stateMerge } from "vue-object-merge";

const bookingsSchema = new schema.Entity("bookings");
const bookingsListSchema = new schema.Array(bookingsSchema);

const wishesSchema = new schema.Entity("wishes", {
  bookings: bookingsListSchema,
});
const wishesListSchema = new schema.Array(wishesSchema);

const union = (original, values) => {
  values.forEach((v) => {
    if (!original.includes(v)) {
      original.push(v);
    }
  });
};

Vue.use(Vuex);
const initialState = () => ({
  wishes: {},
  wishIds: [],
  bookings: {},
  bookingIds: [],
  statuses: [
    {
      status: "wished",
      position: 1,
      icon: "mdi-checkbox-blank-outline",
      title: "Önskad",
      showMine: true,
    },
    {
      status: "booked",
      position: 2,
      icon: "mdi-checkbox-marked",
      title: "Bokad",
      showMine: false,
    },
    {
      status: "purchased",
      position: 3,
      icon: "mdi-cart",
      title: "Inhandlad",
      showMine: false,
    },
    {
      status: "delivered",
      position: 4,
      icon: "mdi-gift",
      title: "Mottagen",
      showMine: true,
    },
    {
      status: "received",
      position: 5,
      icon: "mdi-gift",
      title: "Mottagen",
      showMine: true,
    },
  ],
});

export default {
  state: initialState(),
  getters: {
    wishesSet: (state) => state.wishIds.map((id) => state.wishes[id]),
    wish: (state) => (wishId) => state.wishes[wishId],
    bookingSet: (state) => state.bookingIds.map((id) => state.bookings[id]),

    wishesBelongingTo: (state, getters) => (accountId) => {
      return getters.wishesSet.filter((w) =>
        w.belongs_to.some((bt) => bt.account === parseInt(accountId))
      );
    },

    isWishBelongingToAccount: (state, getters) => (wishId, accountId) => {
      return getters
        .wish(wishId)
        .belongs_to?.some((bt) => bt.account === accountId);
    },

    isWishManagedByAccounts: (state, getters) => (wishId, accountIds) => {
      return getters
        .wish(wishId)
        .belongs_to?.some((bt) => accountIds?.includes(bt.account));
    },

    isWishBookedByAccounts: (state, getters) => (wishId, accountIds) => {
      return getters
        .wish(wishId)
        .bookings?.some((b) => accountIds.includes(state.bookings[b].account));
    },
    wishStatus: (state, getters, rootState) => (wishId) => {
      const wish = getters.wish(wishId);
      if (wish.status === "received") {
        return "received";
      } else if (
        wish.bookings.some((b) => state.bookings[b].status === "delivered")
      ) {
        return "delivered";
      } else if (
        !getters.isWishBelongingToAccount(
          wishId,
          rootState.accounts.primaryAccount
        ) &&
        wish.bookings.some((b) => state.bookings[b].status === "purchased")
      ) {
        return "purchased";
      } else if (
        !getters.isWishBelongingToAccount(
          wishId,
          rootState.accounts.primaryAccount
        ) &&
        wish.bookings.length > 0
      ) {
        return "booked";
      } else {
        return "wished";
      }
    },
  },
  mutations: {
    addWish(state, wish) {
      Vue.set(state.wishes, wish.id, wish);
      state.wishIds.push(wish.id);
    },
    addBooking(state, booking) {
      Vue.set(state.bookings, booking.id, booking);
      state.bookingIds.push(booking.id);
      state.wishes[booking.wish].bookings.push(booking.id);
    },
    setWishes(state, payload) {
      stateMerge(state.wishes, payload.wishes ?? []);
      union(state.wishIds, payload.wishIds);
      state.wishes = { ...state.wishes }; // Lite tråkigt sätt att trigga reaktiviteten
    },
    setBookings(state, payload) {
      stateMerge(state.bookings, payload.bookings ?? []);
      union(state.bookingIds, payload.bookingIds);
    },
    setWishStatus(state, { id, status }) {
      Vue.set(state.wishes[id], "status", status);
      Vue.set(state.wishes[id], "modified_on", new Date().toISOString());
      state.wishIds = [...state.wishIds]; // Lite tråkigt sätt att trigga reaktiviteten
    },
    updateBooking(state, booking) {
      state.bookings[booking.id] = booking;
      state.wishes[booking.wish] = { ...state.wishes[booking.wish] }; // Lite tråkigt sätt att trigga reaktiviteten
    },
    deleteBooking(state, booking) {
      state.bookings[booking.id] = undefined;
      state.bookingIds = state.bookingIds.filter((w) => w !== booking.id);
      state.wishes[booking.wish].bookings = state.wishes[
        booking.wish
      ].bookings.filter((w) => w !== booking.id);
    },
    deleteWish(state, id) {
      state.wishes[id] = undefined;
      state.wishIds = state.wishIds.filter((w) => w !== id);
    },
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  actions: {
    async addWish(context, wish) {
      const belongsToArray = wish.belongs_to;
      wish.belongs_to = belongsToArray.map((id) => ({ account: { id: id } }));
      return client.createItem("wishes", wish).then((res) => {
        const newWish = {
          ...res.data,
          bookings: [],
          belongs_to: belongsToArray.map((id) => ({ account: id })),
        };
        newWish.owner = { id: newWish.owner };
        context.commit("addWish", newWish);
      });
    },

    async editWish(context, wish) {
      const originalWish = context.state.wishes[wish.id];
      const deleteBelongsTo = originalWish.belongs_to
        .filter((bt) => !wish.belongs_to.includes(bt.account))
        .map((bt) => ({ id: bt.id, $delete: true }));
      const newBelongsTo = wish.belongs_to
        .filter(
          (account) =>
            !originalWish.belongs_to.some((bt) => bt.account === account)
        )
        .map((id) => ({ account: { id: id } }));
      wish.belongs_to = deleteBelongsTo.concat(newBelongsTo);
      return client
        .updateItem("wishes", wish.id, wish)
        .then((res) => context.dispatch("getWish", wish.id));
    },

    async getWish(context, id) {
      return context.dispatch("getWishesByFilter", {
        "filter[id][eq]": id,
      });
    },

    async getWishesByAccounts(context, belongsTo) {
      return context.dispatch("getWishesByFilter", {
        "filter[belongs_to.account][in]": belongsTo.join(","),
      });
    },

    async getWishesByFilter(context, filter) {
      client
        .getItems("wishes", {
          fields: "*,belongs_to.id,belongs_to.account,bookings.*",
          ...filter,
          limit: 5000,
        })
        .then((res) => context.dispatch("commitWishesAndBookings", res.data));
    },

    async setWishStatus(context, wish) {
      return client
        .updateItem("wishes", wish.id, {
          status: wish.status,
        })
        .then((res) => context.commit("setWishStatus", res.data));
    },

    async addBooking(context, booking) {
      booking.status = "booked";
      return client
        .createItem("bookings", booking)
        .then((res) => context.commit("addBooking", res.data));
    },

    async updateBooking(context, booking) {
      if (!booking.id) {
        booking.id = context.getters.bookingSet.find(
          (b) => b.wish === booking.wish && b.account === booking.account
        ).id;
      }
      return client
        .updateItem("bookings", booking.id, booking)
        .then((res) =>
          context.commit(
            booking.status === "unbooked" ? "deleteBooking" : "updateBooking",
            booking
          )
        );
    },

    async deleteWish(context, id) {
      return client
        .updateItem("wishes", id, { status: "deleted" }) // Känns lite bättre att radera mjukt eftersom att det kan finnas kopplingar till.
        .then((res) => context.commit("deleteWish", id));
    },

    commitWishesAndBookings(context, wishesResponse) {
      const normalizedWishes = normalize(wishesResponse, wishesListSchema);
      context.commit("setWishes", {
        wishes: normalizedWishes.entities.wishes,
        wishIds: normalizedWishes.result,
      });
      const normalizedBookings = normalize(
        normalizedWishes.entities.bookings ?? [],
        bookingsListSchema
      );
      context.commit("setBookings", {
        bookings: normalizedBookings.entities.bookings,
        bookingIds: normalizedBookings.result,
      });
    },
  },
};
