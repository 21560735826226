import Vue from "vue";
import Vuex from "vuex";
import client from "@/utils/ApiClient";
import { generateToken } from "@/utils/StringUtils";
import { normalize, schema } from "normalizr";
import { union, uniq, without } from "lodash";
import { sortAccounts } from "../../utils/StoreUtils";

const PREMIUM_ROLE_ID = 4; // NOTE: HÅRDKODAT ROLL-ID!
const accountsSchema = new schema.Entity("accounts");
const accountsListSchema = new schema.Array(accountsSchema);

Vue.use(Vuex);
const initialState = () => ({
  accounts: {},
  accountIds: [],
  myAccountIds: [],
  activeAccount: null,
  primaryAccount: null,
  user: null,
});

export default {
  state: initialState(),
  getters: {
    accountSet: (state) =>
      state.accountIds.map((id) => state.accounts[id]).sort(sortAccounts),
    getActiveAccount: (state) => state.accounts[state.activeAccount],
    getPrimaryAccount: (state) => state.accounts[state.primaryAccount],
    getManagedAccounts: (state, getters) =>
      getters.accountSet.filter((a) => state.myAccountIds.includes(a.id)),
    hasManagedAccounts: (state) => state.myAccountIds.length > 1,
    hasPremiumAccount: (state) =>
      parseInt(state.user?.role) === PREMIUM_ROLE_ID,
    isLoggedIn: (state) => Boolean(state.activeAccount),
    isManagedByMe: (state) => (accountId) =>
      state.myAccountIds.includes(accountId),
  },
  mutations: {
    updateAccount(state, account) {
      Vue.set(state.accounts, account.id, account);
    },
    setAccounts(state, payload) {
      state.accounts = Object.assign({}, state.accounts, payload.accounts);
      state.accountIds = union(state.accountIds, payload.accountIds);
    },
    setUser(state, user) {
      state.user = user;
    },
    setPrimaryAccount(state, id) {
      state.primaryAccount = parseInt(id);
    },
    setMyAccounts(state, accounts) {
      state.myAccountIds = accounts;
    },
    setActiveAccount(state, id) {
      state.activeAccount = parseInt(id);
    },
    addManagedAccount(state, accountId) {
      state.myAccountIds.push(accountId);
    },
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  actions: {
    async login(context, credentials) {
      if (credentials === "facebook") {
        return context.dispatch("facebookLogin");
      }
      const userResponse = await client.login({
        ...credentials,
        persist: true,
      });
      await context.dispatch("setUserData", userResponse.data.user);
    },

    async signup(context, { firstname, lastname, birthdate, password, email }) {
      const createdUser = await client.createUser({
        status: "active",
        first_name: firstname,
        last_name: lastname,
        email,
        password,
      });
      const loggedInUser = await client.login({
        email,
        password,
        persist: true,
      });
      const account = await context.dispatch("createAccount", {
        firstname,
        lastname,
        birthdate,
        userId: createdUser.data.id,
      });
      await client.updateUser(createdUser.data.id, {
        account: account.id,
      });
      await context.dispatch("setUserData");
    },

    async createAccount(context, { firstname, lastname, birthdate, userId }) {
      const account = await client.createItem("accounts", {
        status: "published",
        token: generateToken(),
        firstname,
        lastname,
        birthdate,
      });
      await client.createItem("account2user", {
        account: account.data.id,
        user: userId,
      });
      return account.data;
    },

    async createManagedAccount(
      context,
      { firstname, lastname, birthdate, alsoManagedBy }
    ) {
      alsoManagedBy.push(context.state.primaryAccount);
      const managedByUsers = await client
        .getItems("directus_users", {
          "filter[account][in]": alsoManagedBy.join(","),
          fields: "id",
        })
        .then((res) => res.data.map((user) => ({ user })));
      const account = await client.createItem("accounts", {
        status: "published",
        token: generateToken(),
        firstname,
        lastname,
        birthdate,
        managed_by: managedByUsers,
      });
      await context.dispatch("getAccountsByIds", [account.data.id]);
      context.commit("addManagedAccount", account.data.id);
      return account.data;
    },

    async updateAccountSettings(
      { commit, state, dispatch },
      { accountId, firstname, lastname, birthdate, managedByAccountIds }
    ) {
      const oldManagedByAccountIds = state.accounts[accountId].managed_by
        .map((mb) => mb.user?.account)
        .filter((id) => !!id);
      const addManagedByAccountIds = uniq(
        managedByAccountIds.filter((id) => !oldManagedByAccountIds.includes(id))
      );
      const addManagedByUsers = await client
        .getItems("directus_users", {
          "filter[account][in]": addManagedByAccountIds.join(","),
          fields: "id",
        })
        .then((res) => res.data.map((user) => ({ user: { id: user.id } })));
      const deleteManagedByUsers = uniq(
        oldManagedByAccountIds.filter((id) => !managedByAccountIds.includes(id))
      )
        .map(
          (accountIdToDelete) =>
            state.accounts[accountId].managed_by.find(
              (mb) =>
                mb.account.id === accountId &&
                mb.user?.account === accountIdToDelete
            ).id
        )
        .map((id) => ({ id, $delete: true }));
      await client.updateItem("accounts", accountId, {
        firstname,
        lastname,
        birthdate,
        managed_by: [...addManagedByUsers, ...deleteManagedByUsers],
      });
      const account = await client
        .getItems("accounts", {
          "filter[id][eq]": accountId,
          fields: "*,managed_by.*,managed_by.user.account",
        })
        .then((res) => res.data[0]);
      commit("updateAccount", account);

      if (addManagedByUsers.length > 0) {
        const sharedToAccounts = await client
          .getItems("friendships", {
            fields: "*",
            "filter[friend][eq]": accountId,
          })
          .then((res) => res.data.map((f) => f.account));
        addManagedByAccountIds.forEach(async (newId) => {
          if (newId !== accountId && !sharedToAccounts.includes(newId)) {
            await dispatch("shareToFriend", {
              account: newId,
              friend: accountId,
            });
          }
        });
      }
      return account;
    },

    async upgradeToPremium(context) {
      return client
        .updateUser(context.state.user.id, {
          role: PREMIUM_ROLE_ID,
        })
        .then((res) => context.commit("setUser", res.data));
    },

    async resetPassword(context, email) {
      const redirect = encodeURIComponent(
        encodeURIComponent(window.location.pathname)
      ); // Tyvärr måste man dubbelencoda för att inte Apache ska smälla
      const encodedEmail = encodeURIComponent(email);
      return client.requestPasswordReset(
        email,
        `${client.appUrl}/resetpassword/${encodedEmail}/${redirect}`
      );
    },

    async setPassword(context, { password, token, email, redirect }) {
      return client
        .resetPassword(password, token)
        .then(() =>
          context.dispatch("login", {
            email: decodeURIComponent(email),
            password,
          })
        )
        .then(
          () =>
            (window.location.pathname = decodeURIComponent(
              decodeURIComponent(redirect)
            ))
        ); // Send user to redirect. Tyvärr måste man dubbelencoda för att inte Apache ska smälla
    },

    async setUserData(context, user) {
      if (!user) {
        user = await client.getMe().then((res) => res.data);
      }
      await context.dispatch("getFriendshipsByAccount", user.account);
      await context.dispatch("getManagedAccounts", user.id);
      await context.dispatch("getSharedToFriends", user.account);
      await context.commit("setPrimaryAccount", user.account);
      await context.commit("setActiveAccount", user.account);
      await context.commit("setUser", user);
    },

    async facebookLogin(context) {
      console.log("facebook");
      return client.facebookLogin().then((response) => {
        console.log(response);
        context.commit("updateMe", {
          isLoggedIn: true,
          firstname: response.data.user.first_name,
          lastname: response.data.user.last_name,
          email: response.data.user.email,
          id: response.data.user.id,
        });
      });
    },

    async getManagedAccounts(context, managedBy) {
      const accounts = await context.dispatch("getAccountsByFilter", {
        "filter[managed_by.user][eq]": managedBy,
      });
      context.commit("setMyAccounts", accounts);
      await context.dispatch("getGroupsByAccounts", accounts);
    },

    async getAccountsByIds(context, accountIds) {
      return await context.dispatch("getAccountsByFilter", {
        "filter[id][in]": accountIds,
      });
    },

    async getAccountsByFilter(context, filter) {
      const res = await client.getItems("accounts", {
        ...filter,
        fields: "*,managed_by.*,managed_by.user.account",
        limit: 5000,
      });
      const normalizedAccounts = normalize(res.data, accountsListSchema);
      context.commit("setAccounts", {
        accounts: normalizedAccounts.entities.accounts,
        accountIds: normalizedAccounts.result,
      });
      await context.dispatch("getWishesByAccounts", normalizedAccounts.result);
      return normalizedAccounts.result;
    },

    async getPublicAccount(context, id) {
      const account = await client.getItems("accounts", {
        "filter[id][eq]": id,
        fields: "*,managed_by.*,managed_by.user.account",
      });
      const normalizedAccounts = normalize(account.data, accountsListSchema);
      context.commit("setAccounts", {
        accounts: normalizedAccounts.entities.accounts,
        accountIds: normalizedAccounts.result,
      });
      await context.dispatch("getWishesByAccounts", normalizedAccounts.result);
      return normalizedAccounts.result[0];
    },

    async logout(context) {
      await client.logout();
      context.commit("reset");
    },
  },
};
