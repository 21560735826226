import Vue from "vue";
import Vuex from "vuex";
import client from "@/utils/ApiClient";
import { normalize, schema } from "normalizr";
import { merge, union } from "lodash";

const friendSchema = new schema.Entity("friends");

const friendshipsSchema = new schema.Entity("friendships", {
  friend: friendSchema,
});
const friendshipsListSchema = new schema.Array(friendshipsSchema);

Vue.use(Vuex);
const initialState = () => ({
  friendships: {},
  friendshipIds: [],
  shareCount: 0,
  sharedToFriendIds: [],
});

export default {
  state: initialState(),
  getters: {
    friendshipsSet: (state) =>
      state.friendshipIds.map((id) => state.friendships[id]),
  },
  mutations: {
    setFriendships(state, payload) {
      merge(state.friendships, payload.friendships);
      state.friendshipIds = union(state.friendshipIds, payload.friendshipIds);
    },
    addFriendship(state, payload) {
      state.friendships[payload.id] = payload;
      state.friendshipIds.push(payload.id);
    },
    setSharedToFriendIds(state, friendIds) {
      state.sharedToFriendIds = friendIds;
    },
    shareToFriend(state, friendship) {
      state.sharedToFriendIds.push(friendship.account);
    },
    reset(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  actions: {
    async getFriendshipsByAccount(context, account) {
      return client
        .getItems("friendships", {
          fields: "*,friend.*",
          "filter[account][eq]": account,
          limit: 5000,
        })
        .then((res) => {
          const normalizedFriendships = normalize(
            res.data,
            friendshipsListSchema
          );
          context.commit("setFriendships", {
            friendships: normalizedFriendships.entities.friendships,
            friendshipIds: normalizedFriendships.result,
          });
          const normalizedFriends = normalize(
            normalizedFriendships.entities.friends ?? [],
            [friendSchema]
          );
          context.commit("setAccounts", {
            accounts: normalizedFriends.entities.friends,
            accountIds: normalizedFriends.result,
          });
          context.dispatch("getWishesByAccounts", normalizedFriends.result);
        });
    },

    async addFriendship(context, friendship) {
      if (
        context.getters.friendshipsSet.some(
          (f) =>
            f.account.id === friendship.account &&
            f.friend === friendship.friend
        )
      ) {
        return null;
      } else {
        return client
          .createItem("friendships", friendship)
          .then((res) => context.commit("addFriendship", res.data));
      }
    },

    async getSharedToFriends(context, account) {
      return client
        .getItems("friendships", {
          fields: "*",
          "filter[friend][eq]": account,
        })
        .then((res) => {
          context.commit(
            "setSharedToFriendIds",
            res.data.map((f) => f.account)
          );
        });
    },

    async shareToFriend(context, friendship) {
      return client.createItem("friendships", friendship).then((res) => {
        if (friendship.friend === context.rootState.accounts.primaryAccount) {
          context.commit("shareToFriend", res.data);
        }
      });
    },
  },
};
