<template>
  <v-alert
    v-model="visible"
    type="info"
    colored-border
    border="left"
    elevation="2"
    :icon="icon"
    :dismissible="!noDismiss"
  >
    <slot>{{ translations[id] }}</slot>
    <div class="text-right mt-2">
      <slot name="actions" :dismiss="dismiss" :visible="visible"></slot>
    </div>
  </v-alert>
</template>

<script>
// TODO: Move translations to vuex + fetch from api

const translations = {
  theHamburgerIsYourFriend:
    "Önskelistor, bokningar, inställningar m.m. hittar du genom att klicka på hamburgaren som alltid finns där för dig uppe i högra hörnet.",
  addLinksToDescription:
    "Tips: Lägg till länkar i beskrivningen för att göra det tydligt vad du önskar dig",
  addFriendsByScanningQrCode:
    "Här kan du enkelt lägga till dina vänners önskelistor genom att skanna deras qr-kod. Din egen kod hittar du längst ner på sidan.",
  yourBookings:
    "Här hittar du de önskningar som du har bokat. Vartefter de är inhandlade kan du markerade dem som inhandlade/mottagna.",
  youCanInviteMoreMembersWithLink:
    "Tips: Efter att du har skapat gruppen kan du bjuda in fler genom att skapa en länk som du delar med dem.",
};
export default {
  name: "Info",
  props: {
    id: String,
    noDismiss: Boolean,
    icon: String,
  },
  data: () => ({
    visible: true,
  }),
  computed: {
    translations() {
      return translations;
    },
  },
  methods: {
    dismiss() {
      this.visible = false;
    },
  },
  mounted() {
    const dismissed = JSON.parse(localStorage.dismissed ?? "{}");
    if (dismissed[this.id] !== undefined) {
      this.visible = !dismissed[this.id];
    }
  },
  watch: {
    visible(visible) {
      const dismissed = JSON.parse(localStorage.dismissed ?? "{}");
      dismissed[this.id] = !visible;
      localStorage.dismissed = JSON.stringify(dismissed);
    },
  },
};
</script>
