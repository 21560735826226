export const generateJunctionTableUpdate = (
  previousState,
  newState,
  foreignKey
) => {
  const newItems = newState
    .filter((n) => !previousState.some((p) => p[foreignKey] === n[foreignKey]))
    .map((i) => Object.assign(i, { [foreignKey]: { id: i[foreignKey] } }));

  const deletedItems = previousState
    .filter((p) => !newState.some((n) => n[foreignKey] === p[foreignKey]))
    .map((i) => ({ id: i.id, $delete: true }));

  const modifiedItems = [];
  previousState.forEach((p) => {
    const n = newState.find((n) => n[foreignKey] === p[foreignKey]);
    if (n) {
      let isModified = false;
      const modified = {
        id: p.id,
        [foreignKey]: { id: p[foreignKey] },
      };
      for (const key in n) {
        if (
          key !== "id" &&
          key !== foreignKey &&
          typeof n[key] !== "object" &&
          n[key] !== p[key]
        ) {
          modified[key] = n[key];
          isModified = true;
        }
      }
      if (isModified) {
        modifiedItems.push(modified);
      }
    }
  });

  return newItems.concat(deletedItems).concat(modifiedItems);
};
