<template>
  <v-navigation-drawer
    :value="$store.state.drawer"
    @input="$store.commit('toggleDrawer', $event)"
    app
    clipped
    right
    mobile-breakpoint="600"
    color="grey lighten-4"
  >
    <v-list dense class="grey lighten-4">
      <template v-for="(item, i) in menuItems">
        <v-row v-if="item.heading" :key="i" align="center">
          <v-col cols="12">
            <v-subheader v-if="item.heading">
              {{ item.heading }}
            </v-subheader>
          </v-col>
        </v-row>

        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-4"
        ></v-divider>

        <v-list-item v-else :key="i" @click="() => onClick(item)">
          <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="grey--text">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import client from "@/utils/ApiClient";
import { mapState, mapGetters } from "vuex";

export default {
  name: "NavigationDrawer",
  props: {
    value: Boolean,
  },
  computed: {
    ...mapGetters(["groupsSet", "accountSet", "menuItems"]),
  },
  methods: {
    onClick(item) {
      if (item.path) {
        this.$router.push(item.path);
      } else if (item.action) {
        this.$store.dispatch(item.action);
      }
    },
  },
};
</script>
