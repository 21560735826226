import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { useVuetify } from "@/plugins/vuetify";
import VueBus from "@/utils/VueBus";
import VuetifyConfirm from "vuetify-confirm";

Vue.config.productionTip = false;
const vuetify = useVuetify(Vue);

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: "OK",
  buttonFalseText: "Avbryt",
});

Vue.use(VueBus);
// Vue.use(InAppPurchase, { vuex: store });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
